<template>
  <div>
    <v-tabs v-model="currentTab">
      <v-tab v-for="(tab, index) in terminalsActive" :key="index"> T {{ tab.id }} </v-tab>
      <div :class="[terminalsActive.length ? 'ml-1' : 'ml-4', 'my-2']">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="addTerminal" color="blue-grey lighten-3" dark v-bind="attrs" v-on="on" block depressed height="100%">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Додати термінал</span>
        </v-tooltip>
      </div>

      <template v-if="isComponentRerendered">
        <v-tab-item v-for="(tab, index) in terminalsActive" :key="tab.id" class="tab-item pa-3">
          <Terminal
            :terminalId="terminalsActive[index].id"
            :servicePoint="currentItem"
            :servicePointLocation="currentItem.location"
            @disconnected="$emit('terminalDisconnected')"
          />
        </v-tab-item>
      </template>
    </v-tabs>

    <!--    TODO: move to single component if necessary -->
    <v-dialog v-model="openDialog" max-width="1000">
      <v-card>
        <v-card-title>
          <span class="text-h5">Підключення терміналу до торгівельної точки</span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attr }">
              <v-icon class="ml-2" v-bind="attr" v-on="on" size="18" color="primary">mdi-information-outline</v-icon>
            </template>
            Після підключення нового девайса інформація в моніторингу оновиться через кілька хвилин
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                Оберіть, будь ласка, номер терміналу, який хочете підключити до пункту до пункту обслуговування
              </v-col>
              <v-col cols="12" sm="6">
                <PagingSelect
                  v-model="selectedTerminal"
                  :options="terminals"
                  :options-meta="terminalsMeta"
                  :options-params="terminalsOptions"
                  item-value="id"
                  @change="(value) => (selectedTerminal = value)"
                  :error-messages="inputErrors('selectedTerminal')"
                  @input="$v.selectedTerminal.$touch()"
                  @blur="$v.selectedTerminal.$touch()"
                >
                  <template v-slot:selection="{ item }"> T{{ item.id }} (IMEI: {{ item.imei }}) </template>
                  <template v-slot:item="{ item }"> T{{ item.id }} (IMEI: {{ item.imei }}) </template>
                </PagingSelect>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedLocation"
                  :items="currentItem.location"
                  item-value="value"
                  label="Оберіть розташування підключенного термінала"
                  :disabled="!selectedTerminal"
                  :error-messages="inputErrors('selectedLocation')"
                  @input="$v.selectedLocation.$touch()"
                  @blur="$v.selectedLocation.$touch()"
                />
                <v-dialog v-model="dialogAddLocation" max-width="500">
                  <template v-slot:activator="{ on, attr }">
                    <v-btn v-bind="attr" v-on="on" :disabled="!selectedTerminal">Додати розташування</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5"> Додати розташування до точки </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-text-field v-model="newLocation" label="Вкажіть назву нового розташування"></v-text-field>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="addLocation">Так</v-btn>
                      <v-btn text @click="dialogAddLocation = false">Нi</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveTerminal">Так</v-btn>
          <v-btn text @click="closeDialogConnect">Нi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import forceRerender from '@/mixins/forceRerender'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import PagingSelect from '@/components/common/PagingSelect.vue'

export default {
  name: 'ServicePointDevices',

  components: {
    PagingSelect,
    Terminal: () => import('./Terminal'),
  },

  mixins: [validationMixin, forceRerender],
  emits: ['terminalDisconnected'],

  validations: {
    selectedTerminal: { required },
    selectedLocation: { required },
  },

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    editCard: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data: () => ({
    openDialog: false,
    selectedTerminal: null,
    terminalsOptions: null,
    currentTab: null,
    newLocation: null,
    selectedLocation: null,
    dialogAddLocation: false,
  }),

  computed: {
    ...mapState('terminals', ['terminals', 'terminalsMeta', 'terminal']),
    currentCompanyId() {
      return this.$route.params.id
    },
    terminalsActive() {
      return this.currentItem.terminals.filter((terminal) => terminal.status === 1)
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('terminals', ['loadTerminals', 'loadSelectedTerminal', 'updateSelectedTerminal']),
    ...mapActions('servicePoints', ['addTerminalToServicePoint', 'loadSelectedServicePoint', 'updateSelectedServicePoint']),
    async initialize() {
      this.terminalsOptions = {
        loadingFunction: this.loadTerminals,
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
          status: 1,
          show_unconnected: 1,
        },
      }
    },

    async addTerminal() {
      await this.loadTerminals(this.terminalsOptions.payload)
      this.openDialog = true
    },

    async saveTerminal() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        const payload = {
          companyId: this.currentCompanyId,
          terminalId: this.selectedTerminal.id,
        }
        await this.loadSelectedTerminal(payload)
        const terminalParams = {
          ...payload,
          servicePointId: this.currentItem.id,
        }
        const servicePointParams = {
          companyId: this.currentCompanyId,
          servicePointId: this.currentItem.id,
        }
        await this.addTerminalToServicePoint(terminalParams)
        if (this.selectedLocation) {
          const terminalUpdateParams = {
            companyId: this.currentCompanyId,
            terminalId: this.selectedTerminal.id,
            updatedTerminal: {
              bill_acceptor_capacity: this.terminal.bill_acceptor_capacity,
              imei: this.terminal.imei,
              location: this.selectedLocation,
              producer: this.terminal.producer,
            },
          }
          await this.updateSelectedTerminal(terminalUpdateParams)
        }
        await this.loadSelectedServicePoint(servicePointParams)
        await this.forceRerender()
        this.openDialog = false
        this.selectedTerminal = null
        this.selectedLocation = null
        this.currentTab = this.terminalsActive.length - 1
      }
    },

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v[fieldName].$dirty) return errors
      !this.$v[fieldName].required && errors.push('Це поле обов"язкове')
      return errors
    },
    async addLocation() {
      if (this.newLocation) {
        this.loading = true
        const payload = {
          companyId: this.currentCompanyId,
          servicePointId: this.currentItem.id,
          updatedServicePoint: { ...this.currentItem, location: [...this.currentItem.location, this.newLocation] },
        }
        delete payload.updatedServicePoint.id
        delete payload.updatedServicePoint.terminals
        await this.updateSelectedServicePoint(payload)
        this.selectedLocation = this.newLocation
        this.newLocation = null
        this.loading = false
        this.dialogAddLocation = false
      }
    },

    closeDialogConnect() {
      this.openDialog = false
      this.$v.$reset()
      this.$nextTick(() => {
        this.selectedTerminal = null
        this.selectedLocation = null
      })
    },
  },
}
</script>
